import { Component, OnInit } from '@angular/core';
import { ApiResponse, PromptRequest } from 'src/app/models/verioner-url';
import { VersionerUrlService } from 'src/app/core/services/versioner-url.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-article-versioner',
  templateUrl: './article-versioner.component.html',
  styleUrls: ['./article-versioner.component.css']
})
export class ArticleVersionerComponent implements OnInit {
  versionData: ApiResponse | null = null;

  constructor(private versionerService: VersionerUrlService) {}

  ngOnInit(): void {
    const storedData = sessionStorage.getItem('versionData');
    if (storedData) {
      try {
        this.versionData = JSON.parse(storedData);
        if (!this.versionData.data) {
          console.error('No se encontraron datos completos en la respuesta.');
        }
      } catch (e) {
        console.error('Error al parsear los datos de sessionStorage:', e);
      }
    } else {
      console.error('No se encontraron datos en sessionStorage');
    }
  }

  sendNewPrompt(promptValue: string): void {
    if (!this.versionData?.data?.body) {
      Swal.fire('Error', 'No hay contenido disponible para iterar.', 'error');
      return;
    }

    if (!promptValue.trim()) {
      Swal.fire('Error', 'Ingresa un prompt válido', 'error');
      return;
    }

    Swal.fire({
      title: 'Espere por favor...',
      didOpen: () => Swal.showLoading(),
      allowOutsideClick: false,
    });

    const promptRequest: PromptRequest = {
      prompt: promptValue,
      content: this.versionData.data.body.replace(/["'\\\n]/g, '')
    };

    this.versionerService.newVersion(promptRequest).subscribe({
      next: (newText) => {
        Swal.close();
        if (newText.code === '200') {
          this.versionData.data.body = newText.data;
          Swal.fire('Respuesta exitosa', '', 'success');
        } else {
          console.error('Error en la respuesta de la API:', newText.data);
          Swal.fire('Error', 'Error en la respuesta de la API', 'error');
        }
      },
      error: (err) => {
        Swal.close();
        console.error('Error en la respuesta:', err);
        Swal.fire('Error', 'Error en la respuesta, intente nuevamente', 'error');
      }
    });
  }
}
