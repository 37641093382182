export const environment = {
  production: true,
  staging: false,
  apiKey: 'RedaccionApp',
  apiCerberoUrl: 'https://cerbero.pulzo.com/cerbero/v2',
  pageSize: 25,
  release: '3.5.8',
  sentry: 'https://590a4dfdf84187bed4bbacb77440dd17@o215667.ingest.us.sentry.io/4507115306221568',
  secretKey: 'jbe4jb43hjb56oie5jtil34894',
  nameAppHub: 'RedaccionApp',
  cerberoFrontURL: 'https://hub.pulzo.com',
  geaAppURL: 'https://mercurio.pulzo.com',
  scrollArticlesPath : 'https://s3-us-west-2.amazonaws.com/filesstaticpulzo/pulzo-lite/jsons/admin/scroll_articles.json',
  permissionsNewsletter:['coordinadorredaccion','redactor'],
  permissions: [{
    role:"coordinadorredaccion",
    actions:{
      edit:true,
      delete:true,
      create:true,
      program: true
    },
  },
  {
    role:"redactor",
    actions:{
      edit:false,
      delete:false,
      create:false,
      program: true
    },
  }

  ],  
};