import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiResponse, Generator, PromptRequest, PromptResponse } from 'src/app/models/verioner-url'; 
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionerUrlService {
  private baseUrl = `${env.apiCerberoUrl}`;
  httpClient: any;

  constructor(private http: HttpClient) { }

  generarVersion(url: Generator) {
    return this.http.post<ApiResponse>(`${this.baseUrl}/request?event=generador-texto`, url);
  }

  newVersion(IArequest: PromptRequest) {
    return this.http.post<PromptResponse>(`${this.baseUrl}/request?event=solve-one-prompt`,IArequest);
    
  }  

}
