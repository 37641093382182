<app-back-header link='/home-versioner'></app-back-header>

<!-- Contenedor principal con dos columnas -->
<div class="min-h-screen flex flex-row p-4">

  <!-- Columna Izquierda: Artículo -->
  <div class="w-2/3 bg-white p-8 rounded-lg shadow mr-4">
    <h1 class="text-2xl not-italic font-bold leading-8 mb-6">Versionador de artículos</h1>

    <div *ngIf="versionData?.data" class="space-y-6">
      <div class="flex flex-col">
        <label class="text-base text-dark-light">Título</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">
          {{ versionData?.data?.titulo }}
        </div>
      </div>
      
      <div class="flex flex-col">
        <label class="text-base text-dark-light">Subtítulo</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">
          {{ versionData?.data?.subtitulo }}
        </div>
      </div>
      
      <div class="flex flex-col">
        <label class="text-base text-dark-light">Cuerpo del texto</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4 whitespace-pre-line">
          {{ versionData?.data?.body }}
        </div>
      </div>
      
      <div class="flex flex-col">
        <label class="text-base text-dark-light">URL Utilizada</label>
        <a [href]="versionData?.data?.url_usada" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-light-light p-4">
          {{ versionData?.data?.url_usada }}
        </a>
      </div>
    </div>
  </div>

  <!-- Columna Derecha: Nuevo Prompt -->
  <div class="w-1/3 bg-white p-8 rounded-lg shadow">
    <h2 class="text-xl font-bold mb-4">Nuevo prompt</h2>
    <textarea #promptTextArea rows="6" class="w-full p-2 border border-gray-300 rounded-md mb-4 whitespace-pre-line" placeholder="Ingresa un nuevo prompt aquí..."></textarea>
    <button (click)="sendNewPrompt(promptTextArea.value)" class="w-44 h-10 font-bold py-2 px-4 rounded-md hover:bg-green-700 bg-green-700 text-white">Enviar</button>
  </div>
</div>
